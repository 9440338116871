import type { RouteRecordRaw } from 'vue-router'
import PdfImportView from '@/views/project/tools/PdfImportView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const pdfImportRoute: RouteRecordRaw = {
  path: 'pdfImport',
  name: 'pdfImport',
  component: PdfImportView,
  meta: {
    breadcrumb: 'pdfImport',
    menu: {
      key: 'pdfImport',
      icon: 'mdi-file-pdf-box',
      name: 'pdfImport'
    },
    scopes: [KeycloakScope.UNIT_LIST, KeycloakScope.UNIT_UPDATE]
  }
}

export default pdfImportRoute
