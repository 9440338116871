<template>
  <div class="building-chooser-container">
    <player-error-boundary>
      <v-structure-chooser
        :i18n-config="i18nConfig"
        :structure-chooser-data="props.project.buildingChooserData"
        :color-callback="colorCallback"
        :cdn-file-resolver="cdnFileResolver"
        :custom-overlay-content="project.buildingChooserCustomOverlay"
        :version="version"
        @on-click="goToBuilding"
      >
        <template #popup="{ hoverId }">
          <div class="pa-4 bg-white">
            {{ props.buildings.find((b) => b.publicId === hoverId)?.name }}&nbsp;({{
              props.units.filter(
                (u) => props.buildings.find((b) => b.publicId === hoverId)?.id === u.buildingId
              ).length
            }}&nbsp;{{ $t('units') }})
          </div>
        </template>
      </v-structure-chooser>
    </player-error-boundary>
  </div>
</template>

<script setup lang="ts">
import {
  VStructureChooser,
  type Color,
  type HoverState,
  type I18NConfig
} from '@qispace/vue3-player'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import PlayerErrorBoundary from './PlayerErrorBoundary.vue'
import type Building from '@/types/building'
import type Project from '@/types/project'
import type Status from '@/types/status'
import type Unit from '@/types/unit'

const props = defineProps<{
  units: Array<Unit>
  buildings: Array<Building>
  project: Project
  statuses: Array<Status>
  version?: string
}>()

const version = computed(() => props.version)
const router = useRouter()
const i18n = useI18n()
const i18nConfig = ref<I18NConfig>({
  labels: {
    seeApartment: i18n.t('seeApartment')
  },
  language: i18n.locale.value
})

function hexToRgb(hex: string) {
  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = regex.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16)
      }
    : {
        r: 0,
        g: 0,
        b: 0,
        a: 0
      }
}

function colorCallback(buildingId: string, state: HoverState): Color | undefined {
  const building = props.buildings.find((b) => b.publicId === buildingId)
  if (building && building.active) {
    const units = props.units.filter((u) => u.buildingId === building.id)
    const statusIds = units.map((u) => u.status)
    const statusesMap = statusIds.reduce<{ [key: number]: number }>((acc, id) => {
      if (!acc[id]) {
        acc[id] = 0
      }
      acc[id]++
      return acc
    }, {})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const orderedStatusEntries = Object.entries(statusesMap).sort(([_, a], [__, b]) => b - a)
    let status: Status | undefined
    let index = 0
    while (index < orderedStatusEntries.length) {
      const id = parseInt(orderedStatusEntries[index][0])
      status = props.statuses.find((s) => s.id === id)!
      index++
      if (!status.isSold || index === orderedStatusEntries.length) {
        break
      }
    }
    if (status) {
      if (state === 'idle') {
        if (status.persistentBuildingColor) {
          return {
            ...hexToRgb(status.idleColor),
            s: status.idleStrokeWidth
          }
        }
      } else if (state === 'hover') {
        return {
          ...hexToRgb(status.highlightColor),
          s: status.highlightStrokeWidth
        }
      }
    } else {
      return { ...hexToRgb('#888888'), s: 1 }
    }
  }
  return undefined
}

function cdnFileResolver(filename: string) {
  return `${props.project.assetRoot}/buildings/images${filename}`
}

function goToBuilding(publicId: string) {
  const building = props.buildings.find((b) => b.publicId === publicId)
  if (building && building.active) {
    router.push(`/buildings/${building.id}`)
  }
}
</script>

<style scoped>
.building-chooser-container {
  max-width: 1280px;
  position: relative;
}
</style>
