import type { RouteRecordRaw } from 'vue-router'
import ifcUploadsRoute from './ifcUploads'
import exportRoute from './export'
import importRoute from './import'
import pdfImportRoute from './pdfImport'

const toolsRoute: RouteRecordRaw = {
  path: '/tools',
  name: 'tools',
  redirect: { name: 'export' },
  meta: {
    breadcrumb: 'tools',
    menu: {
      key: 'tools',
      icon: 'mdi-tools',
      name: 'tools'
    }
  },
  children: [exportRoute, importRoute, pdfImportRoute, ifcUploadsRoute]
}

export default toolsRoute
