<template>
  <v-row v-if="hasSelectEventListener" class="bg-grey-lighten-4 pa-5">
    <div class="d-flex align-center justify-left">
      <div v-for="image in selected">
        <v-hover v-slot="{ isHovering, props }">
          <div class="ma-1 pa-2 bg-white position-relative cursor-pointer" v-bind="props">
            <v-img
              :src="`${project.assetRoot}/images/${image.category}/${image.filename}`"
              height="100"
              width="100"
              cover
              @click="previewSelection = image"
            />

            <v-expand-transition>
              <div
                v-if="isHovering"
                class="d-flex transition-fast-in-fast-out selected-image-hover-overlay position-absolute bottom-0 left-0 right-0"
                style="height: 30%"
              >
                <v-btn
                  size="x-small"
                  variant="text"
                  color="error"
                  class="selected-image-delete-btn position-absolute"
                  @click="toggleSelect(image)"
                >
                  {{ t('remove') }}
                </v-btn>
              </div>
            </v-expand-transition>
          </div>
        </v-hover>
      </div>
    </div>
  </v-row>
  <v-divider></v-divider>
  <v-row>
    <v-col cols="12">
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn size="small" prepend-icon="mdi-filter" v-bind="props"> Filter </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in ProjectImagesCategory"
                :key="index"
                :value="index"
              >
                <v-list-item-title>
                  <v-checkbox :label="item" v-model="categoryFilter" :value="item"></v-checkbox>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="flex-grow-1">
          <v-pagination
            size="small"
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
          ></v-pagination>
        </div>
        <div class="pagination-items-per-page">
          <v-select
            v-model="itemsPerPage"
            :items="[8, 16, 32, 48]"
            :label="t('itemsPerPage')"
          ></v-select>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row dense>
    <ResizableColumns>
      <template #left>
        <v-card class="h-100">
          <div class="image-grid">
            <div class="d-flex flex-wrap">
              <div
                v-for="(image, index) in itemsToDisplay"
                class="ma-1 cursor-pointer border"
                @click="previewSelection = image"
              >
                <v-hover>
                  <template v-slot="{ isHovering, props }">
                    <v-card
                      v-bind="props"
                      class="mx-auto"
                      min-width="200"
                      max-width="200"
                      :key="index"
                      :color="isHovering ? 'primary' : undefined"
                    >
                      <v-img
                        :src="`${project.assetRoot}/images/${image.category}/${image.filename}`"
                        height="200"
                        cover
                      />

                      <div v-if="hasSelectEventListener" class="select-checkbox position-absolute">
                        <v-btn
                          :class="{ 'd-none': !isSelected(image) }"
                          size="x-small"
                          icon="mdi-check"
                          color="success"
                          @click="toggleSelect(image)"
                        ></v-btn>

                        <v-btn
                          :class="{ 'd-none': isSelected(image) || !isHovering }"
                          size="x-small"
                          @click="toggleSelect(image)"
                          >{{ t('select') }}</v-btn
                        >
                      </div>

                      <v-card-title :title="image.filename" class="mt-3"
                        >{{ image.filename }}
                      </v-card-title>

                      <div
                        v-bind="props"
                        :class="{ 'd-none': !isHovering }"
                        class="delete-btn position-absolute top-0 right-0"
                      >
                        <v-btn
                          v-if="hasDeleteEventListener"
                          size="x-small"
                          icon="mdi-delete"
                          color="error"
                          @click="onDelete(image)"
                        ></v-btn>
                      </div>
                    </v-card>
                  </template>
                </v-hover>
              </div>
            </div>
          </div>
        </v-card>
      </template>

      <template #right>
        <div v-if="previewSelection">
          <v-card class="mx-auto pb-10 align-center">
            <v-img
              :src="`${project.assetRoot}/images/${previewSelection.category}/${previewSelection.filename}`"
              cover
            />

            <v-card-title :title="previewSelection.filename" class="mt-3"
              >{{ previewSelection.filename }}
            </v-card-title>
            <v-card-subtitle> {{ previewSelection.category }} </v-card-subtitle>

            <v-card-actions v-if="hasSelectEventListener">
              <v-btn
                :class="{ 'd-none': !isSelected(previewSelection) }"
                size="x-small"
                icon="mdi-check"
                color="success"
                @click="toggleSelect(previewSelection)"
              ></v-btn>

              <v-btn
                :class="{ 'd-none': isSelected(previewSelection) }"
                size="x-small"
                color="primary"
                @click="toggleSelect(previewSelection)"
              >
                {{ t('select') }}
              </v-btn>
            </v-card-actions>

            <div class="close-btn position-absolute top-0 right-0">
              <v-btn size="x-small" icon="mdi-close" @click="previewSelection = null"></v-btn>
            </div>
          </v-card>
        </div>
      </template>
    </ResizableColumns>
  </v-row>
  <v-row>
    <v-col cols="12">
      <div class="d-flex align-center justify-space-between">
        <div>&nbsp;</div>
        <div class="flex-grow-1">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
          ></v-pagination>
        </div>
        <div class="pagination-items-per-page">
          <v-select
            v-model="itemsPerPage"
            :items="[8, 16, 32, 48]"
            :label="t('itemsPerPage')"
          ></v-select>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {
  computed,
  type ComputedRef,
  getCurrentInstance,
  onMounted,
  ref,
  type Ref,
  watch
} from 'vue'
import type Image from '@/types/image'
import type Project from '@/types/project'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import ResizableColumns from './ResizableColumns.vue'
import { ProjectImagesCategory } from '@/types/image'

const props = defineProps({
  project: { type: Object, default: () => {} },
  images: { type: Array<Image>, default: () => [] },
  preSelected: { type: Array<number>, default: () => [] },
  multiple: { type: Boolean, default: false },
  hasSelectEventListener: { type: Boolean, default: false }
})

const emit = defineEmits<{
  delete: [image: Image]
  reorder: [reordered: Array<Partial<Image>>]
  select: [images: Array<Image>]
}>()

const selected: Ref<Array<Image> | null> = ref(null)
const previewSelection: Ref<Image | null> = ref(null)

onMounted(() => {
  selected.value = props.preSelected
    .map((id) => props.images.find((i) => i.id === id)!)
    .filter((i) => i)
})

watch(selected, () => {
  emit('select', selected.value || [])
})

const categoryFilter = ref<ProjectImagesCategory[]>(Object.values(ProjectImagesCategory))

// pagination

watch(categoryFilter, () => {
  currentPage.value = 1
})
const sortedAndFilteredImages: ComputedRef<Array<Image>> = computed(() => {
  return props.images
    .sort((a, b) => {
      return b.id - a.id
    })
    .filter((i) => {
      if (categoryFilter.value.length === 0) {
        return true
      }
      return categoryFilter.value.includes(i.category)
    })
})
const currentPage = ref(1)
const totalItems = computed(() => props.images.length)
const itemsPerPage = ref(16)

watch(itemsPerPage, () => {
  currentPage.value = 1
})

const itemsToDisplay = computed(() => {
  return sortedAndFilteredImages.value.slice(
    (currentPage.value - 1) * itemsPerPage.value,
    currentPage.value * itemsPerPage.value
  )
})

const totalPages = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value)
})

const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)

const { t } = useI18n()

function onDelete(image: Image) {
  emit('delete', image)
}

const toggleSelect = (img: Image) => {
  if (!props.hasSelectEventListener) {
    return
  }

  if (!props.multiple) {
    selected.value = [img]
    previewSelection.value = img
    return
  }

  if (selected.value && selected.value.find((i) => i.id === img.id)) {
    selected.value = selected.value.filter((i) => i.id !== img.id)
    previewSelection.value = null
  } else {
    if (!selected.value) {
      selected.value = []
    }

    if (selected.value && selected.value.find((i) => i.id === img.id)) {
      previewSelection.value = img
      return
    }
    previewSelection.value = img
    selected.value = [...selected.value, img]
  }
}

const isSelected = (img: Image) => {
  if (selected.value) {
    return selected.value.find((i) => i.id === img.id)
  }
  return false
}
</script>

<style scoped>
:deep(img) {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.select-checkbox {
  bottom: 18%;
  left: 10px;
}

.current-selection-preview .select-checkbox {
  bottom: 18%;
  left: 15px;
}

.pagination-items-per-page {
  min-width: 180px;
}

.selected-image-delete-btn {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.selected-image-hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
